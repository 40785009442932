import React from 'react';

import { CaseStudyOverview } from 'src/containers/CaseStudyOverview';
import { SEO } from 'src/containers/SEO';
import NotFoundPage from '../404';

export default function FourOhFourPage() {
  return (
    <NotFoundPage
      subtitle="Sorry, this customer story no longer exists. Below are some more stories you might be interested in"
      indexUrl="/customers/"
    >
      <SEO description="Sorry, this customer story no longer exists." />
      <CaseStudyOverview />
    </NotFoundPage>
  );
}
